import React from "react";

import { cn } from "~/utilities/cn";
import { isValidUrl } from "~/utilities/helpers/isUrl";

export interface ImageProps {
  isLocalImage?: boolean;
  url: string;
  alt?: string;
  className?: string;
}

const Image: React.FC<ImageProps> = ({
  isLocalImage = false,
  url,
  alt = "Image description",
  className = "",
}) => {
  if (!url) return null;

  const isFullUrl = isLocalImage || isValidUrl(url);

  const imageUrl = isFullUrl ? url : ENV.IMAGE_DOMAIN_URL + url;

  return (
    <img
      src={imageUrl}
      alt={alt}
      className={cn("h-full w-full object-cover", className)}
    />
  );
};

export default Image;
